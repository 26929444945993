import React from 'react';

import config from '../../config';
import LogoImg from "../assets/images/LOGO.png"

export default function Footer() {
  return (
    
    <header id="header">
      <img src={LogoImg}  alt="" />
      <h1>{config.heading}</h1>
      <p>{config.subHeading}</p>
    </header>
  );
}
